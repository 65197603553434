<template>
<div>
  <el-dialog
    width="500px"
    :title="`${isChange ? '处理' : ''}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
    <el-form
      class="staff-from"
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='100px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-form-item label="商品名称" prop="good_name">
        <el-input
          v-model="form.data.good_name"
          :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="退货数量" prop="refund_quantity">
        <el-input
          v-model="form.data.refund_quantity"
          :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="规格" prop="spec_name">
        <el-input
          v-model="form.data.spec_name"
          :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="退货原因" prop="refund_type">
        <el-input
          v-model="form.data.refund_type"
          :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="问题描述" prop="refund_desc">
        <el-input
          v-model="form.data.refund_desc"
          :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="凭证照片" prop="name">
        <el-image
          v-for="(img, index) in form.data.refund_pictures"
          :key="index"
          style="width: 100px; height: 100px;margin:5px "
          :src="img"
          fit="cover"
          :preview-src-list="form.data.refund_pictures"></el-image>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="center">
        <el-button type="danger" plain @click='verify(2)'>拒绝</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='verify(1)'>同意</el-button>
      </el-row>
    </template>
  </el-dialog>

  <el-dialog
    :title="is_agree == 2 ? '审核不通过' : '审核通过'"
    :visible.sync="dialogFormVisible"
    :modal="true"
    width="500px"
    append-to-body
  >
    <el-form :model="form.data" :rules="form.rules" ref="formName">
      <el-form-item label="原因" label-width="100px" prop="refuse_refund_desc" v-if="is_agree == 2">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入审核不通过原因"
          v-model="form.data.refuse_refund_desc"
          clearable
          maxlength="200"
        ></el-input>
      </el-form-item>
      <el-form-item label="处理方式" prop="deal_type" v-if="is_agree == 1">
        <el-radio-group v-model="form.data.deal_type">
          <el-radio :label="1">退货入库</el-radio>
          <el-radio :label="2">退还供应商</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="仓库" prop="stash_id" v-if="is_agree == 1 && form.data.is_stash == 0">
        <el-select v-model="form.data.stash_id" @focus="getStash" placeholder="请选择">
          <el-option
            v-for="item in stashArr"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" :loading="form.loading" @click="dialogSub(2)">确 定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
  import { mixinTable } from '@/mixins/table.js'
  import common from '@/util'
  export default {
    name: 'sendServiceEdit',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            good_name: '',
            spec_name: '',
            refund_quantity: '',
            refund_type: '',
            refund_desc: '',
            refund_pictures: [],
            deal_type: 1, //处理方式 1:退货入库 2:退还供应商
            stash_id:'',
            refuse_refund_desc:'',
          },
          rules: {
            refuse_refund_desc: [{ required: true, message:'请输入不通过原因', trigger: 'change'}],
            deal_type: [{ required: true, message:'请选择出来方式', trigger: 'change'}],
            stash_id: [{ required: true, message:'请选择仓库', trigger: 'change'}],
          }
        },
        dialogFormVisible: false,
        is_agree: 1, // 1通过，2拒绝
        stashArr: [],
      }
    },
    methods: {
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
        console.log(this.toStr({1:'质量问题',2:'发错货',3:'漏发少发'}, row.refund_type))
        this.form.data.refund_type = this.toStr({1:'质量问题',2:'发错货',3:'漏发少发'}, row.refund_type)
        // this.form.data.refund_pictures = row.refund_pictures[0].split('|')

      },
      // 获取启用中的仓库
      getStash() {
        this.$http.get('/admin/stash/list', { params:{ page: 1, count: 10000, status:1 }}).then(res => {
          if(res.code == 1) {
            this.stashArr = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.form.data.refuse_refund_desc = '';
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 审核 is_agree: 1.通过， 2.拒绝 
      verify(type) {
        this.is_agree = type;
        this.dialogFormVisible = true;
      },
      // 不通过点击事件
      dialogSub() {
        this.$refs["formName"].validate((valid) => {
          if (valid) {
            let data = {};
            if(this.is_agree == 2) { // 拒绝
              data = {
                id: this.form.data.id,
                is_agree: 2, // 2.拒绝
                deal_type: 0, // 拒绝时处理方式默认传0
                refuse_refund_desc: this.form.data.refuse_refund_desc, // 不同意的理由
              }
            } else { // 同意
              data = {
                id: this.form.data.id,
                is_agree: 1, // 1.同意
                deal_type: this.form.data.deal_type, // 处理方式 1:退货入库 2:退还供应商
                stash_id: this.form.data.stash_id,
              }
            }
            this.verifyFun(data)
          }
        });
      },
      verifyFun(data) {
        this.form.loading = true;
        this.$http.post('/admin/order/refund', data).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功！')
            this.dialogFormVisible = false;
            this.toggle(false)
            this.$emit('refresh')
          }
        }).finally(() => {
          this.form.loading = false;
        })
      },
    }
  }
</script>
